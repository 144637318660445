import { isLocalhost } from "./EnvHelper";

export const getBearerToken = async () => {
  let token = sessionStorage.getItem("BEARER_TOKEN");
  if ((token ==="" || token === null) && isLocalhost()) {
    const getDefaultToken = async () => {
      const appConstants = await import(
        /* webpackChunkName: "AppConstants" */ "../../common/constants/AppConstants"
      );
      const { DEFAULT_TOKEN } = appConstants;
      return DEFAULT_TOKEN();
    };
    token = await getDefaultToken();
  }
  return token;
};

export const setBearerToken = (token: string) => {
  sessionStorage.setItem("BEARER_TOKEN", token);
};
