export const ApiUrlConfig = {
  User: {
    GetUserDetails: "Users/GetUser",
  },
  CreateCycle: {
    createNewCycle: "CycleMaster/AddCycle",
    currentCycleDetails: "CycleMaster/GetCurrentCycle",
    updateCycle: "CycleMaster/UpdateCycle",
    fiscalYearData: "CycleMaster/GetFiscalYear",
    getAllCycles: "CycleMaster/GetAllCycles",
  },
  ConfigurationFileUpload: {
    EligibilityRule: {
      uploadEligibilityRules: "EligibilityRules/UploadExcel",
      getEligibilityRuleData: "EligibilityRules/GetEligibilityRuleData",
      deleteEligibilityRuleRowData: "EligibilityRules/DeleteEligibilityRows",
      updateEligibilityRuleRowData: "EligibilityRules/EditEligibilityRow",
      addEligibilityRuleNewRow: "EligibilityRules/AddEligibilityRow",
      deleteEligibilityData: "EligibilityRules/DeleteEligibilityRuleData",
      validateEligibilityUpload: "EligibilityRules/EligibilityUploadValidate",
      getEligibilityRuleColumnData: "EligibilityRules/GetEligibilityHeader",
    },
    BudgetData: {
      uploadBudgetFile: "Budget/UploadExcel",
      getBudgetData: "Budget/GetBudgetDetails",
      deleteBudgetDataRowData: "Budget/DeleteBudgetRows",
      updateBudgetDataRowData: "Budget/EditBudgetRow",
      addBudgetDataNewRow: "Budget/AddBudgetRow",
      getBudgetDataColumnData: "Budget/GetBudgetHeader",
      validateBudgetUpload: "Budget/BudgetUploadValidate",
      deleteBudgetData: "Budget/DeleteBudgetFileData",
    },
    PreviousCycleBonus: {
      uploadBonusFile: "PreviousCycleBonus/InsertPreviousCycleBonusData",
      validateBonusFileUpload:
        "PreviousCycleBonus/ValidatePreviousCycleBonusUpload",
      deleteBonusFileData: "PreviousCycleBonus/DeletePreviousCycleBonusData",
      getPreviouCycleBonusData: "Worksheet/GetWorksheetDetails",
      getEmployeeCount: "Worksheet/WorksheetEmployeeCount",
      getWorkSheetColumnMapping: "Worksheet/GetWorksheetMappingColumns",
      getWorkSheetTabName: "Worksheet/GetAllheaders",
      setZeroStateStatus: "Worksheet/updatePreviousCycleStatus",
      importWorksheet: "Worksheet/InsertWorksheetData",
      getEmpVpayDetailsSummary: "Worksheet/GetSingleRowFromWorksheet",
      initiateWorksheetFileExport: "Worksheet/ExportWorksheetData",
      getWorksheetFile: "Worksheet/GetWorksheetFile",
      getFinalOutputFile: "Worksheet/GetFinalOutputFile",
      worksheetHistoryExport: "Worksheet/ExportWorksheet",
    },
    EntityMapping: {
      uploadEntityMappingFile: "EntityMapping/EntityMappingExcelUpload",
      validateEntityMappingFile: "EntityMapping/EntityMappingUploadValidate",
      deleteEntityMappingFile: "EntityMapping/DeleteEntityMappingFileData",
      getEntityMappingData: "EntityMapping/GetEntityMappingDetails",
      deleteEntityMappingRowData: "EntityMapping/DeleteEntityMappingRows",
      updateEntityMappingRowData: "EntityMapping/UpdateEntityMapping",
      addEntityMappingNewRow: "EntityMapping/AddEntityMapping",
      getEntityMappingColumnData: "EntityMapping/GetEntityMappingHeader",
    },
  },
  ProcessData: {
    uploadProcessData: "CycleMaster/AddDataAtCycleInitiatePauseMaster",
    processedDataDetails: "CycleMaster/GetCycleHistory",
    getProcessReprocessFlag: "CycleMaster/CycleProcessOrReprocess",
    closeCycle: "CycleMaster/ChangeCycleStatusToClose",
  },
  DataChecklist: {
    deleteAllSourceFiles: "ClearData/ClearAllFile",
    deleteSpecificFile: "ClearData/ClearSingleFile",
    sourceFileHistoryRowData: "FileProcess/GetSourceFileHistory",
    // getProcessSourceFiles: "FileProcess/ProcessVPayFiles",
    getProcessSourceFiles: "FileProcess/ProcessAzureVPayFiles",
    getSourceFilesStatus: "FileProcess/FileUploadStatus",
    getIndividualSourceFileRowData: "FileProcess/GetSourceFileData",
    getErrorRecords: "FileProcess/GetErrorRecordFromSourceFileData",
    getSuccessRecords: "FileProcess/GetSuccessRecordFromSourceFileData",
  },
  Dashboard: {
    getBudgetDetails: "Dashboard/GetBudgetDetails",
    getEntityData: "Dashboard/GetAllEntities",
  },
  AzureBlob: {
    getDropdownData: "AzureBlob/GetFilesDropdown",
    uploadFileType: "AzureBlob/UploadFile",
    processVpayCalculation: "VPayCalculation/ProcessVariablePay",
    updateIsProcessedFlag: "VPayCalculation/UpdateIsProcessed",
  },
  BackendLogs: {
    getAppLog: "BackendLog/GetAppLogData",
    getCycleChangeHistory: "BackendLog/GetCycleChangeHistory",
    getVpayLog: "BackendLog/GetVPayProcessLog",
    getLogColumnData: "BackendLog/GetBackendLogColumnMapping",
    getLogTabName: "BackendLog/GetBackendLogHeaders",
  },
  Troubleshooting: {
    uploadTroubleshootingData: "Troubleshooting/TroubleShooting",
  },
  Reprocess: {
    reprocessFilesData: "Reprocess/ReprocessFiles",
  },
};
