import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import History from "services/helper/History";
import ReduxStore from "store/indexView";
import { loadAlertNotification } from "store/NotificationAction/NotificationAction";
import * as progressAction from "store/ProgressAction/ProgressActions";
//   import { captureError } from "./DataDogHelper";
import { isProduction } from "./EnvHelper";
import { getBearerToken } from "./MsalHelper";

export class AxiosHelper {
  private static instance: AxiosInstance;

  public static get Instance(): AxiosInstance {
    if (!AxiosHelper.instance) {
      AxiosHelper.instance = Axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });
      AxiosHelper.initializeRequestInterceptor();
      AxiosHelper.initializeResponseInterceptor();
    }
    return AxiosHelper.instance;
  }

  public static initializeResponseInterceptor = () => {
    AxiosHelper.instance.interceptors.response.use(
      AxiosHelper.handleResponse,
      AxiosHelper.handleError
    );
  };

  public static initializeRequestInterceptor = () => {
    AxiosHelper.instance.interceptors.request.use(
      AxiosHelper.handleRequest,
      AxiosHelper.handleError
    );
  };

  public static handleRequest = async (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    const newConfig = { ...config };
    newConfig.headers["Cache-Control"] = "no-cache, no-store";
    newConfig.headers["Access-Control-Allow-Origin"] = "*";
    newConfig.headers.Authorization = `Bearer ${await getBearerToken()}`;
    return newConfig;
  };

  public static handleResponse = (response: AxiosResponse) => {
    if (!response.data && response.status !== 200) {
      Promise.reject(response);
    }
    return response;
  };

  public static handleError = (error: any) => {
    const errorConfig = { ...error };
    const reduxStore = ReduxStore.Instance;
    //   captureError(erroR);
    if (errorConfig && errorConfig.response.status === 401 && isProduction()) {
      History.push("/logout");
      // const request = {
      //   ...ApiTokenRequest,
      //   account: MsalPublicClientApplicationInstance.getMsalAccount(),
      // };
      // try {
      //   const { accessToken } = await MsalPublicClientApplicationInstance.Instance.acquireTokenSilent(
      //     request
      //   );
      //   setBearerToken(accessToken);
      //   errorConfig.request.config.headers.Authorization = `Bearer ${accessToken}`;
      //   return AxiosHelper.instance(errorConfig.request.config);
      //   // return AxiosHelper.instance.request(errorConfig.config);
      // } catch (e) {
      //   return error;
      // }
    } else if (errorConfig.response.status === 400) {

        window.location.href = "badRequestError.html";

    } else if (errorConfig.message.includes("504")) {
      window.location.href = "errorScreen.html";
    } else {
      reduxStore.dispatch(
        loadAlertNotification({
          message: errorConfig.response.data.detail,
          type: "error",
        })
      );
    }
    reduxStore.dispatch(progressAction.endApiCall());
  };
}
