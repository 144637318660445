/* eslint-disable default-param-last */
import { IUploadProcessData } from "models/processData/IUploadProcessData";
import * as ProcessDataActionTypes from "./ProcessDataActionTypes";

export interface ProcessDataReducerState {
  ProcessDataDetails: IUploadProcessData[];
}
const initialState: ProcessDataReducerState = {
  ProcessDataDetails: [],
};

export const ProcessDataReducer = (
  state = initialState,
  action: ProcessDataActionTypes.ProcessDataActionTypes
): ProcessDataReducerState => {
  const { type } = action;
  switch (type) {
    case ProcessDataActionTypes.LOAD_PROCESS_DATA_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        ProcessDataDetails: payload,
      };
    }
    default:
      return state;
  }
};
