import { lazy, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { getAzureUserDetails } from "services/helper/AuthenticationHelper";
import { isProduction } from "services/helper/EnvHelper";
import { isLogoutPage } from "services/helper/LogoutHelper";
import { isEmpty } from "services/helper/ValueHelper";
// import { isEmpty } from "services/helper/ValueHelper";
import { useReduxSelector } from "store/index";
import { loadUserDetails } from "store/WelcomePage/WelcomePageActions";

import IProvider from "./IProvider";

const ErrorScreens = lazy(
  () =>
    import(
      /* webpackChunkName: "ErrorScreens" */ "components/ErrorScreen/AccessDenied"
    )
);

const UserProvider = ({ children }: IProvider) => {
  const dispatch = useDispatch();
  const userDetails = useReduxSelector((state) => state.welcomePageData.user);
  //   const mapData = useReduxSelector((state) => state.tubemap.mapData);
  useLayoutEffect(() => {
    if (isLogoutPage()) return;
    if (isProduction()) {
      const azureDetails = getAzureUserDetails();
      if (!azureDetails) return;
      dispatch(loadUserDetails());
    } else {
      import(
        /* webpackChunkName: "AppConstants" */ "common/constants/AppConstants"
      ).then((appConstants) => {
        const { DEFAULT_USER } = appConstants;
        dispatch(loadUserDetails());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(userDetails)) {
    return null;
  }
  return userDetails.userID === 0 ? <ErrorScreens /> : children;
  // return children;
};

export default UserProvider;
