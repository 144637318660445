import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import IProvider from "../../providers/IProvider";
import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MsalPublicClientApplicationInstance } from "../../services/helper/MsalPublicClientApplicationInstance";
import { storeRoute } from "../../services/helper/PathHelperForRoute";
import { loginRequest } from "../../ssoConfig/authConfig";

export default function MsalProtectComponent({ children }: IProvider) {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const [showChildren, setShowChildren] = useState(false);
  useLayoutEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          await MsalPublicClientApplicationInstance.getApiBearerToken();
        } catch (error) {
          
          history.replace("/logout");
        } finally {
          setShowChildren(true);
        }
      })();
    } else if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/logout"
    ) {
      storeRoute(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={LoadingIndicator}
    >
      {showChildren && children}
    </MsalAuthenticationTemplate>
  );
}
