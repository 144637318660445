/* eslint-disable default-param-last */
import { IUser } from "models/User/IUser";
import * as WelcomePageTypes from "./WelcomePageActionTypes";

export interface WelcomePageReducerState {
  user: IUser;
  contniueButtonSelected: boolean | null;
}

const initialState: WelcomePageReducerState = {
  user: null,
  contniueButtonSelected: null,
};

export const WelcomePageReducer = (
  state = initialState,
  action: WelcomePageTypes.WelcomePageTypes
): WelcomePageReducerState => {
  switch (action.type) {
    case WelcomePageTypes.LOAD_USER_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        user: payload,
      };
    }
    case WelcomePageTypes.LOAD_USER_ERROR: {
      const { payload } = action;
      return {
        ...state,
        user: payload,
      };
    }
    case WelcomePageTypes.USER_CLICKED_CONTNIUE: {
      const { payload } = action;
      return {
        ...state,
        contniueButtonSelected: payload,
      };
    }

    default:
      return state;
  }
};
