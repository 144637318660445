/* eslint-disable default-param-last */
import {
  IFiscalYearData,
  ILoadCurrentCycleData,
} from "models/createNewCycle/ICreateNewCycle";
import * as CreateCycleActionTypes from "./CreateCycleActionTypes";

export interface CreateCycleReducerState {
  CurrentCycleData: ILoadCurrentCycleData[] | null;
  fiscalYearData: IFiscalYearData[] | null;
  prevousCycleBonusEmployeeCount: number;
  allCycleData: ILoadCurrentCycleData[] | null;
  employeeCount: any;
  selectedDropdownCycleData: any;
  selectedDropdownEntityData: any;
}
const initialState: CreateCycleReducerState = {
  CurrentCycleData: null,
  fiscalYearData: null,
  prevousCycleBonusEmployeeCount: null,
  allCycleData: null,
  employeeCount: null,
  selectedDropdownCycleData: null,
  selectedDropdownEntityData: null,
};

export const CreateCycleReducer = (
  state = initialState,
  action: CreateCycleActionTypes.CreateCycleActionTypes
): CreateCycleReducerState => {
  switch (action.type) {
    case CreateCycleActionTypes.LOAD_CURRENT_CYCLE_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        CurrentCycleData: payload,
      };
    }
    case CreateCycleActionTypes.LOAD_FISCAL_YEAR_DATA: {
      const { payload } = action;
      return {
        ...state,
        fiscalYearData: payload,
      };
    }
    case CreateCycleActionTypes.LOAD_PREVIOUS_CYCLE_BONUS_EMP_COUNT: {
      return { ...state, employeeCount: action.payload };
    }
    case CreateCycleActionTypes.LOAD_ALL_CYCLE_DATA: {
      return { ...state, allCycleData: action.payload };
    }
    case CreateCycleActionTypes.SELECTED_DROPDOWN_CYCLE_DATA: {
      return { ...state, selectedDropdownCycleData: action.payload };
    }
    case CreateCycleActionTypes.SELECTED_ENTITY_DASHBOARD: {
      return { ...state, selectedDropdownEntityData: action.payload };
    }
    default:
      return state;
  }
};
