export const UPLOAD_BUDGET_FILE = "UPLOAD_BUDGET_FILE";
export const UPLOAD_ELIGIBILITY_FILE = "UPLOAD_ELIGIBILITY_FILE";
export const LOAD_UPLOAD_BUDGETFILE_STATUS = "LOAD_UPLOAD_BUDGETFILE_STATUS";
export const LOAD_UPLOAD_ELIGIBILITYFILE_STATUS =
  "LOAD_UPLOAD_ELIGIBILITYFILE_STATUS";
export const LOAD_PREVIOUS_UPLOAD_ELIGIBILITYFILE_STATUS =
  "LOAD_PREVIOUS_UPLOAD_ELIGIBILITYFILE_STATUS";
export const LOAD_UPLOAD_BONUSFILE_STATUS = "LOAD_UPLOAD_BONUSFILE_STATUS";
export const LOAD_ZERO_STATE_STATUS = "LOAD_ZERO_STATE_STATUS";
export const LOAD_UPLOAD_ENTITY_MAPPING_STATUS =
  "LOAD_UPLOAD_ENTITY_MAPPING_STATUS";
export const LOAD_WORKSHEET_ROWS_DATA = "LOAD_WORKSHEET_ROWS_DATA";
export const SET_VPAY_PROGRESS_STATUS = "SET_VPAY_PROGRESS_STATUS";
export const SET_IS_PROCESS_FLAG = "SET_IS_PROCESS_FLAG";
export interface LoadValidatedBudgetFile {
  type: typeof UPLOAD_BUDGET_FILE;
  payload: any;
}
export interface LoadValidatedEligibilityFile {
  type: typeof UPLOAD_ELIGIBILITY_FILE;
  payload: any;
}
export interface LoadValidatedUploadBudgetFile {
  type: typeof LOAD_UPLOAD_BUDGETFILE_STATUS;
  payload: boolean;
}
export interface LoadValidatedUploadEligibilityFile {
  type: typeof LOAD_UPLOAD_ELIGIBILITYFILE_STATUS;
  payload: boolean;
}
export interface LoadPreviousValidatedUploadEligibilityFile {
  type: typeof LOAD_PREVIOUS_UPLOAD_ELIGIBILITYFILE_STATUS;
  payload: boolean;
}
export interface LoadValidatedUploadBonusFile {
  type: typeof LOAD_UPLOAD_BONUSFILE_STATUS;
  payload: boolean;
}
export interface zeroStateFileUploadStatus {
  type: typeof LOAD_ZERO_STATE_STATUS;
  payload: boolean;
}
export interface LoadValidatedEntityMappingFileUploadStatus {
  type: typeof LOAD_UPLOAD_ENTITY_MAPPING_STATUS;
  payload: boolean;
}
export interface LoadWorksheetRows {
  type: typeof LOAD_WORKSHEET_ROWS_DATA;
  payload: any;
}
export interface SetVpayProgressStatus {
  type: typeof SET_VPAY_PROGRESS_STATUS;
  payload: boolean;
}
export interface SetIsProcessFlag {

    type: typeof SET_IS_PROCESS_FLAG;

    payload: boolean;

}
export type RouteActionType =
  | LoadValidatedBudgetFile
  | LoadValidatedEligibilityFile
  | LoadValidatedUploadBudgetFile
  | LoadValidatedUploadEligibilityFile
  | LoadValidatedUploadBonusFile
  | zeroStateFileUploadStatus
  | LoadValidatedEntityMappingFileUploadStatus
  | LoadPreviousValidatedUploadEligibilityFile
  | LoadWorksheetRows
  | SetVpayProgressStatus
  | SetIsProcessFlag;
