import { IToastPayload } from "models/react-toast/IToastPayload";

export const LOAD_NOTIFICATION_ALERT = "LOAD_NOTIFICATION_ALERT";

export interface LoadNotificationActionType {
  type: typeof LOAD_NOTIFICATION_ALERT;
  payload: IToastPayload;
}

export type ToastNotifications = LoadNotificationActionType;
