import { IUser } from "models/User/IUser";

export const USER_CLICKED_CONTNIUE = "USER_CLICKED_CONTNIUE ";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export interface userClickedContinueButton {
  type: typeof USER_CLICKED_CONTNIUE;
  payload: boolean;
}

export interface LoadUserAction {
  type: typeof LOAD_USER_SUCCESS;
  payload: IUser;
}
export interface LoadUserErrorAction {
  type: typeof LOAD_USER_ERROR;
  payload: IUser;
}

export type WelcomePageTypes =
  | userClickedContinueButton
  | LoadUserAction
  | LoadUserErrorAction;
