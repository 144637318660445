import { AccountInfo } from "@azure/msal-browser";
import { ApiTokenRequest } from "../../ssoConfig/authConfig";
import { setBearerToken } from "./MsalHelper";
import { MsalPublicClientApplication } from "./MsalPublicClientApplication";

export class MsalPublicClientApplicationInstance extends MsalPublicClientApplication {
  private static getMsalAccount = (): AccountInfo => {
    return MsalPublicClientApplicationInstance.Instance.getAllAccounts()[0];
  };

  public static getApiBearerToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const tokenConfiguration = {
        ...ApiTokenRequest,
        account: MsalPublicClientApplicationInstance.getMsalAccount(),
      };
      MsalPublicClientApplicationInstance.Instance.acquireTokenSilent(
        tokenConfiguration
      )
        .then(({ accessToken }) => {
          setBearerToken(accessToken);
          resolve(accessToken);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
}
