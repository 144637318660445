import configureStore from "./configureStore";

type ReduxStoreInstance = ReturnType<typeof configureStore>;

class ReduxStore {
  private static instance: ReduxStoreInstance;

  public static get Instance(): ReduxStoreInstance {
    if (!ReduxStore.instance) {
      ReduxStore.instance = configureStore();
    }
    return ReduxStore.instance;
  }
}

export default ReduxStore;
