/* eslint-disable default-param-last */
import * as ConfigurationUploadTypes from "./ConfigurationFileUploadActionTypes";

export interface ConfigurationUploadState {
  budgetFileUploadMessage: any;
  eligibilityUploadMessage: any;
  budgetUploadStatus: any;
  eligibilityUploadStatus: any;
  previousEligibilityUploadStatus: any;
  bonusFileUploadStatus: any;
  entityMappingFileUploadStatus: any;
  zeroStateStatus: boolean;
  worksheetRowsData: any;
  vayProgressStatus: boolean;
  isProcessFlagApi: boolean;
}

const initialState: ConfigurationUploadState = {
  budgetFileUploadMessage: null,
  eligibilityUploadMessage: null,
  budgetUploadStatus: null,
  eligibilityUploadStatus: null,
  previousEligibilityUploadStatus: null,
  bonusFileUploadStatus: null,
  entityMappingFileUploadStatus: null,
  zeroStateStatus: false,
  worksheetRowsData: null,
    vayProgressStatus: false,
    isProcessFlagApi: null,
};
export const ConfigurationFilesUploadReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ConfigurationUploadTypes.RouteActionType
): ConfigurationUploadState => {
  switch (action.type) {
    case ConfigurationUploadTypes.UPLOAD_BUDGET_FILE: {
      return { ...state, budgetFileUploadMessage: action.payload };
    }
    case ConfigurationUploadTypes.UPLOAD_ELIGIBILITY_FILE: {
      return { ...state, eligibilityUploadMessage: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_UPLOAD_BUDGETFILE_STATUS: {
      return { ...state, budgetUploadStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_UPLOAD_ELIGIBILITYFILE_STATUS: {
      return { ...state, eligibilityUploadStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_PREVIOUS_UPLOAD_ELIGIBILITYFILE_STATUS: {
      return { ...state, previousEligibilityUploadStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_UPLOAD_BONUSFILE_STATUS: {
      return { ...state, bonusFileUploadStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_ZERO_STATE_STATUS: {
      return { ...state, zeroStateStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_UPLOAD_ENTITY_MAPPING_STATUS: {
      return { ...state, entityMappingFileUploadStatus: action.payload };
    }
    case ConfigurationUploadTypes.LOAD_WORKSHEET_ROWS_DATA: {
      return { ...state, worksheetRowsData: action.payload };
    }
    case ConfigurationUploadTypes.SET_VPAY_PROGRESS_STATUS: {
      return { ...state, vayProgressStatus: action.payload };
      }
      case ConfigurationUploadTypes.SET_IS_PROCESS_FLAG: {

          return { ...state, isProcessFlagApi: action.payload };

      }
    default:
      return state;
  }
};
