// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const GraphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};

// API Token Authentication Config

export const ApiTokenRequest = {
  scopes: [process.env.REACT_APP_TOKEN_REQUEST_SCOPES],
};
