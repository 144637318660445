import { ILoadBudgetData } from "models/dashboard/ILoadBudgetData";

export const LOAD_BUDGET_DATA_DETAILS = "LOAD_BUDGET_DATA_DETAILS";
export const LOAD_ENTITY_DATA = "LOAD_ENTITY_DATA";
export const SET_DIRTY_FLAG = "SET_DIRTY_FLAG";
export const SET_PROCESS_ALERT_FLAG = "SET_PROCESS_ALERT_FLAG";
export const SET_PROCESS_REPROCESS_MESSAGE_FLAG =
  "SET_PROCESS_REPROCESS_MESSAGE_FLAG";

export interface LoadBudgetDataDetails {
  type: typeof LOAD_BUDGET_DATA_DETAILS;
  payload: ILoadBudgetData[];
}

export interface LoadEntityDataDetails {
  type: typeof LOAD_ENTITY_DATA;
  payload: any;
}

export interface SetRecalculationFlag {
  type: typeof SET_DIRTY_FLAG;
  payload: any;
}
export interface setProcessAlertFlag {
  type: typeof SET_PROCESS_ALERT_FLAG;
  payload: boolean;
}

export interface setProcessReprocessFlag {
  type: typeof SET_PROCESS_REPROCESS_MESSAGE_FLAG;
  payload: boolean;
}
export type DashboardActionType =
  | LoadBudgetDataDetails
  | LoadEntityDataDetails
  | SetRecalculationFlag
  | setProcessReprocessFlag
  | setProcessAlertFlag;
