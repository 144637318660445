export const LOAD_WORKSHEET_COLUMN_DATA = "LOAD_WORKSHEET_COLUMN_DATA";
export const LOAD_WORKSHEET_TABNAME = "LOAD_WORKSHEET_TABNAME";
export const LOAD_EMP_VPAY_DETAILS = "LOAD_EMP_VPAY_DETAILS";

export interface LoadWorkSheetTableColumnData {
  type: typeof LOAD_WORKSHEET_COLUMN_DATA;
  payload: any;
}
export interface LoadWorkSheetTabNameData {
  type: typeof LOAD_WORKSHEET_TABNAME;
  payload: any;
}
export interface LoadEmpVpaySummary {
  type: typeof LOAD_EMP_VPAY_DETAILS;
  payload: any;
}
export type RouteActionType =
  | LoadWorkSheetTableColumnData
  | LoadWorkSheetTabNameData
  | LoadEmpVpaySummary;
