/* eslint-disable default-param-last */
import * as AdminConsoleTypes from "./AdminConsoleActionTypes";

export interface AdminConsoleState {
  isOpen: boolean;
  VpayleftBarScreenNumber: any;
  drawerSelectedEntities: any;
}

const initialState: AdminConsoleState = {
  isOpen: false,
  VpayleftBarScreenNumber: { index: 0, subIndex: 0 },
  drawerSelectedEntities: {},
};
export const AdminConsoleReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: AdminConsoleTypes.RouteActionType
): AdminConsoleState => {
  switch (action.type) {
    case AdminConsoleTypes.UPDATE_DRAWER_ISOPEN_STATE: {
      return { ...state, isOpen: action.payload };
    }
    case AdminConsoleTypes.VPAY_LEFT_BAR_SELECTED_ICON: {
      return { ...state, VpayleftBarScreenNumber: action.payload };
    }
    case AdminConsoleTypes.DRAWER_NAV_SELECTED_ITEM: {
      const { payload } = action;
      return {
        ...state,
        drawerSelectedEntities: payload,
      };
    }
    default:
      return state;
  }
};
