/* eslint-disable default-param-last */
import * as BackendLogTypes from "./BackendLogActionTypes";

export interface BackendLogsTypesState {
  appLogDetails: any;
  cycleChangeHistoryLogsDetails: any;
  vpayLogDetails: any;
  logTabList: any;
  logColumnNameMapping: any;
}

const initialState: BackendLogsTypesState = {
  appLogDetails: null,
  cycleChangeHistoryLogsDetails: null,
  vpayLogDetails: null,
  logTabList: null,
  logColumnNameMapping: null,
};
export const BackendLogsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: BackendLogTypes.RouteActionType
): BackendLogsTypesState => {
  switch (action.type) {
    case BackendLogTypes.LOAD_APP_LOG: {
      return { ...state, appLogDetails: action.payload };
    }
    case BackendLogTypes.LOAD_CYCLE_CHANGE_HISTORY: {
      return { ...state, cycleChangeHistoryLogsDetails: action.payload };
    }
    case BackendLogTypes.LOAD_VPAY_LOG: {
      return { ...state, vpayLogDetails: action.payload };
    }
    default:
      return state;
  }
};
