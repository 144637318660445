export const isEmpty = (value: unknown) =>
  !value ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const IsJsonString = (text: string) => {
  try {
    JSON.parse(text);
  } catch (e) {
    return false;
  }
  return true;
};

export const isDev = () =>
  process.env.NODE_ENV !== "production" ||
  process.env.REACT_APP_ENV === "development";
