import * as APIStatusType from "./ProgressActionTypes";

const apiTypeEndsInSuccess = (type: string) => {
  return type.substr(type.length - 8) === "_SUCCESS";
};

export const ProgressReducer = (
  // eslint-disable-next-line default-param-last
  state = 0,
  action: APIStatusType.ApiStatusAction
): number => {
  if (action.type === APIStatusType.BEGIN_API_CALL) {
    return state + 1;
  }
  if (
    action.type === APIStatusType.END_API_CALL ||
    apiTypeEndsInSuccess(action.type)
  ) {
    return state - 1;
  }
  if (action.type === APIStatusType.RESET_API_CALL) {
    return 0;
  }
  return state;
};
