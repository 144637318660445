import {
  IFiscalYearData,
  ILoadCurrentCycleData,
} from "models/createNewCycle/ICreateNewCycle";

export const LOAD_CURRENT_CYCLE_DETAILS = "LOAD_CURRENT_CYCLE_DETAILS";
export const LOAD_FISCAL_YEAR_DATA = "LOAD_FISCAL_YEAR_DATA";
export const LOAD_PREVIOUS_CYCLE_BONUS_EMP_COUNT =
  "LOAD_PREVIOUS_CYCLE_BONUS_EMP_COUNT";
export const LOAD_ALL_CYCLE_DATA = "LOAD_ALL_CYCLE_DATA";
export const SELECTED_DROPDOWN_CYCLE_DATA = "SELECTED_DROPDOWN_CYCLE_DATA";
export const SELECTED_ENTITY_DASHBOARD = "SELECTED_ENTITY_DASHBOARD";

export interface LoadCurrentCycleDetails {
  type: typeof LOAD_CURRENT_CYCLE_DETAILS;
  payload: ILoadCurrentCycleData[];
}
export interface LoadFiscalYearDetails {
  type: typeof LOAD_FISCAL_YEAR_DATA;
  payload: IFiscalYearData[];
}

export interface LoadAllCycleDetails {
  type: typeof LOAD_ALL_CYCLE_DATA;
  payload: ILoadCurrentCycleData[];
}
export interface LoadPreviousCycleBonusEmployeeCount {
  type: typeof LOAD_PREVIOUS_CYCLE_BONUS_EMP_COUNT;
  payload: any;
}

export interface LoadSelectedCycleDropdownData {
  type: typeof SELECTED_DROPDOWN_CYCLE_DATA;
  payload: any;
}
export interface LoadSelectedEntityDropdownData {
  type: typeof SELECTED_ENTITY_DASHBOARD;
  payload: any;
}
export type CreateCycleActionTypes =
  | LoadCurrentCycleDetails
  | LoadFiscalYearDetails
  | LoadPreviousCycleBonusEmployeeCount
  | LoadAllCycleDetails
  | LoadSelectedCycleDropdownData
  | LoadSelectedEntityDropdownData;
