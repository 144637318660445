import AppProvider from "providers/AppProvider";
import { lazy, StrictMode } from "react";
import "./index.css";

const Routes = lazy(() => import(/* webpackChunkName: "Routes" */ "./Routes"));

const App = () => (
  <StrictMode>
    <AppProvider>
      <Routes />
    </AppProvider>
  </StrictMode>
);
export default App;
