import Loader from "assets/svg/Navbar/loader.gif";
import { CSSProperties, memo } from "react";

function LoadingIndicator() {
  const wrapperStyles: CSSProperties = {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(248, 245, 255, 0.5)",
    zIndex: "9999",
  };

  return (
    <div style={wrapperStyles}>
      <img src={Loader} alt="Loader" />
      <span>Loading...</span>
    </div>
  );
}

export default memo(LoadingIndicator);
