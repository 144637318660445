import * as apiStatusTypes from "./ProgressActionTypes";

export const beginApiCall = (): apiStatusTypes.ApiStatusAction => {
  return {
    type: apiStatusTypes.BEGIN_API_CALL,
  };
};

export const endApiCall = (): apiStatusTypes.ApiStatusAction => {
  return {
    type: apiStatusTypes.END_API_CALL,
  };
};
export const resetApiCallState = (): apiStatusTypes.ApiStatusAction => {
  return {
    type: apiStatusTypes.RESET_API_CALL,
  };
};