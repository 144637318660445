export const LOAD_APP_LOG = "LOAD_APP_LOG";
export const LOAD_CYCLE_CHANGE_HISTORY = "LOAD_CYCLE_CHANGE_HISTORY";
export const LOAD_VPAY_LOG = "LOAD_VPAY_LOG";
export const LOAD_TAB_NAME = "LOAD_TAB_NAME";
export const LOAD_COLUMN_DATA = "LOAD_COLUMN_DATA";

export interface LoadAppLog {
  type: typeof LOAD_APP_LOG;
  payload: any;
}
export interface LoadCycleChangeHistory {
  type: typeof LOAD_CYCLE_CHANGE_HISTORY;
  payload: any;
}
export interface LoadVpayLog {
  type: typeof LOAD_VPAY_LOG;
  payload: any;
}
export interface LoadTabName {
  type: typeof LOAD_TAB_NAME;
  payload: any;
}
export interface LoadColumnData {
  type: typeof LOAD_COLUMN_DATA;
  payload: any;
}
export type RouteActionType =
  | LoadAppLog
  | LoadCycleChangeHistory
  | LoadVpayLog
  | LoadTabName
  | LoadColumnData;
