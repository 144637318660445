import { IToastPayload } from "models/react-toast/IToastPayload";
import * as ToastNotificationsActionTypes from "./NotificationActionTypes";

export const loadAlertNotification = (
  data: IToastPayload
): ToastNotificationsActionTypes.LoadNotificationActionType => {
  return {
    type: ToastNotificationsActionTypes.LOAD_NOTIFICATION_ALERT,
    payload: data,
  };
};
