import { ApiUrlConfig } from "common/constants/ApiUrlConfig";
import { IUser } from "models/User/IUser";
import { Dispatch } from "redux";
import { AxiosHelper } from "services/helper/AxiosHelper";
import * as progressAction from "store/ProgressAction/ProgressActions";
import * as welcomePageActions from "./WelcomePageActionTypes";

export const userSelectContinueButton = (
  data: boolean
): welcomePageActions.userClickedContinueButton => {
  return {
    type: welcomePageActions.USER_CLICKED_CONTNIUE,
    payload: data,
  };
};

const loadUserSuccess = (data: IUser): welcomePageActions.LoadUserAction => {
  return {
    type: welcomePageActions.LOAD_USER_SUCCESS,
    payload: data,
  };
};
const loadUserError = (data: IUser): welcomePageActions.LoadUserErrorAction => {
  return {
    type: welcomePageActions.LOAD_USER_ERROR,
    payload: data,
  };
};

export const loadUserDetails = () => {
  // const reduxStore = ReduxStore.Instance;
  return (dispatch: Dispatch) => {
    dispatch(progressAction.beginApiCall());
    const axiosInstance = AxiosHelper.Instance;
    axiosInstance
      .get<IUser>(`/${ApiUrlConfig.User.GetUserDetails}`)
      .then(({ data }) => {
        if (data.userID) {
          dispatch(loadUserSuccess(data));
        } else {
          dispatch(loadUserError(data));
          dispatch(progressAction.endApiCall());
        }
      })
      .catch((e) => {
        dispatch(progressAction.endApiCall());
        // dispatch(
        //   loadAlertNotification({
        //     message: "something went wrong!!",
        //     type: "error",
        //   })
        // );
      });
  };
};

export const logoutUser = () => {
  return {
    type: welcomePageActions.USER_LOGOUT,
  };
};
