/* eslint-disable global-require */
/* eslint-disable dot-notation */
/* eslint-disable import/no-import-module-exports */
import { applyMiddleware, compose, createStore } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import thunk from "redux-thunk";
import { rootReducer, RootState } from ".";

export default function configureStore(initialState?: RootState) {
  const composeEnhancers =
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose; // add support for Redux dev tools

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
  );

  if (module["hot"]) {
    module["hot"].accept("./index", () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextReducer = require("./index").default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
