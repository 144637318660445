export const UPDATE_DRAWER_ISOPEN_STATE = "UPDATE_DRAWER_ISOPEN_STATE";
export const VPAY_LEFT_BAR_SELECTED_ICON = "VPAY_LEFT_BAR_SELECTED_ICON";
export const DRAWER_NAV_SELECTED_ITEM = "DRAWER_NAV_SELECTED_ITEM";

export interface UpdateDrawerOpenAction {
  type: typeof UPDATE_DRAWER_ISOPEN_STATE;
  payload: boolean;
}
export interface VPayLeftBarNumber {
  type: typeof VPAY_LEFT_BAR_SELECTED_ICON;
  payload: object;
}
export interface DrawerNavSelectedItemAction {
  type: typeof DRAWER_NAV_SELECTED_ITEM;
  payload: object;
}
export type RouteActionType =
  | UpdateDrawerOpenAction
  | VPayLeftBarNumber
  | DrawerNavSelectedItemAction;
