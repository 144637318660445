import "bootstrap/dist/css/bootstrap.css";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReduxStore from "store/indexView";
import App from "./App";
import AuthenticationProvider from "./providers/AuthenticationProvider";
import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(
  <AuthenticationProvider>
    <Provider store={ReduxStore.Instance}>
      <App />
    </Provider>
  </AuthenticationProvider>,
  document.getElementById("root")
);

registerServiceWorker();
