import { MsalProvider } from "@azure/msal-react";
import MsalAuthenticationComponent from "../components/protectComponent/MsalProtectComponent";
import { MsalPublicClientApplication } from "../services/helper/MsalPublicClientApplication";
import IProvider from "./IProvider";
import { isProduction } from "../services/helper/EnvHelper";

const AuthenticationProvider = ({ children }: IProvider) => {
  const msalInstance = MsalPublicClientApplication.Instance;

  return isProduction() ? (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationComponent>{children}</MsalAuthenticationComponent>
    </MsalProvider>
  ) : (
    children
  );
};

export default AuthenticationProvider;
