import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig } from "../../ssoConfig/msalConfig";

export class MsalPublicClientApplication {
  private static instance: PublicClientApplication;

  public static get Instance(): PublicClientApplication {
    if (!MsalPublicClientApplication.instance) {
      const msalConfig = getMsalConfig();
      MsalPublicClientApplication.instance = new PublicClientApplication(
        msalConfig
      );
    }
    return MsalPublicClientApplication.instance;
  }
}
