import { TypeOptions } from "react-toastify";
import * as ToastNotificationsActionTypes from "./NotificationActionTypes";

export interface NotificationReducerState {
  type: TypeOptions;
  message: string;
}

const initialState: NotificationReducerState = {
  message: null,
  type: "default",
};

export const notificationReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ToastNotificationsActionTypes.LoadNotificationActionType
): NotificationReducerState => {
  switch (action.type) {
    case ToastNotificationsActionTypes.LOAD_NOTIFICATION_ALERT: {
      const { payload } = action;
      return {
        ...state,
        type: payload.type,
        message: payload.message,
      };
    }
    default:
      return state;
  }
};
