import { AccountInfo } from "@azure/msal-common";
import { IUser } from "models/User/IUser";
import { isDev, isEmpty, IsJsonString } from "services/helper/ValueHelper";
import { DEFAULT_TOKEN } from "../../common/constants/AppConstants";

export const BearerTokenKey = () => "skills_nav_token_bear_token";
export const userDetailsToken = () => "Skill_Nav_User_Details";

export const getBearerToken = (): string => {
  let token = sessionStorage.getItem(BearerTokenKey());
  if (isEmpty(token) && isDev()) {
    token = DEFAULT_TOKEN();
  }
  return token;
};

export const setBearerToken = (token: string) => {
  sessionStorage.setItem(BearerTokenKey(), token);
};

export const getAzureUserDetails = (): AccountInfo => {
  let azureDetails: AccountInfo;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(sessionStorage)) {
    const storedValue = IsJsonString(value) ? JSON.parse(value) : null;
    if (
      !isEmpty(storedValue) &&
      typeof storedValue === "object" &&
      !isEmpty(storedValue.username)
    ) {
      azureDetails = storedValue;
    }
  }
  return azureDetails;
};

export const getUserData = (): IUser => {
  const userDetails = sessionStorage.getItem(userDetailsToken());
  if (!userDetails) {
    return null;
  }
  return JSON.parse(userDetails);
};
export const isValidUser = () => !isEmpty(getUserData());

export const storeUserData = (userDetails: IUser) => {
  sessionStorage.setItem(userDetailsToken(), JSON.stringify(userDetails));
};

export const clearSessionOnLogout = () => {
  sessionStorage.clear();
};
