import { ILoadBudgetData } from "models/dashboard/ILoadBudgetData";
import * as DashboardActionTypes from "./DashboardActionTypes";

export interface DashoardReducerState {
  BudgetDataDetails: ILoadBudgetData[];
  entityData: any;
  processDirtyFlag: boolean;
  alertFlag: boolean;
  processReprocessMsg: boolean;
}

const initialState: DashoardReducerState = {
  BudgetDataDetails: [],
  entityData: null,
  processDirtyFlag: false,
  alertFlag: false,
  processReprocessMsg: false,
};

export const DashboardReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: DashboardActionTypes.DashboardActionType
): DashoardReducerState => {
  switch (action.type) {
    case DashboardActionTypes.LOAD_BUDGET_DATA_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        BudgetDataDetails: payload,
      };
    }
    case DashboardActionTypes.LOAD_ENTITY_DATA: {
      const { payload } = action;
      return {
        ...state,
        entityData: payload,
      };
    }
    case DashboardActionTypes.SET_DIRTY_FLAG: {
      const { payload } = action;
      return {
        ...state,
        processDirtyFlag: payload,
      };
    }
    case DashboardActionTypes.SET_PROCESS_ALERT_FLAG: {
      const { payload } = action;
      return {
        ...state,
        alertFlag: payload,
      };
    }
    case DashboardActionTypes.SET_PROCESS_REPROCESS_MESSAGE_FLAG: {
      const { payload } = action;
      return {
        ...state,
        processReprocessMsg: payload,
      };
    }
    default:
      return state;
  }
};
