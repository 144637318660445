import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Action, AnyAction, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk/es/types";
import { AdminConsoleReducer } from "store/AdminConsole/AdminConsoleReducer";
import { ConfigurationFilesUploadReducer } from "store/ConfigurationFileUpload/ConfigurationFileUploadReducer";
import { CreateCycleReducer } from "store/CreateCycle/CreateCycleReducer";
import { DashboardReducer } from "store/Dashboard/DashboardReducer";
import { notificationReducer } from "store/NotificationAction/NotificationReducer";
import { ProcessDataReducer } from "store/ProcessData/ProcessDataReducer";
import { ProgressReducer } from "store/ProgressAction/ProgressReducer";
import { USER_LOGOUT } from "store/WelcomePage/WelcomePageActionTypes";
import { WelcomePageReducer } from "store/WelcomePage/WelcomePageReducer";
import { WorkSheetReducer } from "store/WorkSheet/WorkSheetReducer";
import { BackendLogsReducer } from "store/BackendLog/BackendLogReducer";

const appReducer = combineReducers({
  adminLeftBar: AdminConsoleReducer,
  createCycleData: CreateCycleReducer,
  notificationAlert: notificationReducer,
  progress: ProgressReducer,
  welcomePageData: WelcomePageReducer,
  configurationFilesUploadReducer: ConfigurationFilesUploadReducer,
  processDataReducer: ProcessDataReducer,
  dashboardDetails: DashboardReducer,
  workSheetReducer: WorkSheetReducer,
  backendLogsReducer: BackendLogsReducer,
});

export const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;

export type GetState = () => RootState;
