import SuspenseWrapperWithLoader from "components/SuspenseHOC/SuspenseWrapperWithLoader";
import { lazy } from "react";
import IProvider from "./IProvider";
import UserProvider from "./UserProvider";

const MuiProvider = lazy(
  () => import(/* webpackChunkName: "MuiProvider" */ "providers/MuiProvider")
);
const ReduxProvider = lazy(
  () =>
    import(/* webpackChunkName: "ReduxProvider" */ "providers/ReduxProvider")
);
const ApiCallsInProgressProvider = lazy(
  () =>
    import(
      /* webpackChunkName: "ApiCallsInProgressProvider" */ "providers/ApiCallsInProgressProvider"
    )
);
const NotificationAlertProvider = lazy(
  () =>
    import(
      /* webpackChunkName: "ApiCallsInProgressProvider" */ "providers/NotificationProvider/NotificationAlertProvider"
    )
);
function AppProvider({ children }: IProvider) {
  return (
    <SuspenseWrapperWithLoader>
      <MuiProvider>
        <ApiCallsInProgressProvider>
          <NotificationAlertProvider>
            <UserProvider>{children}</UserProvider>
          </NotificationAlertProvider>
        </ApiCallsInProgressProvider>
      </MuiProvider>
    </SuspenseWrapperWithLoader>
  );
}

export default AppProvider;
