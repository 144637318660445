/* eslint-disable default-param-last */
import * as WorksheetTypes from "./WorkSheetActionTypes";

export interface WorkSheetDataState {
  // workSheetColumnData: any;
  worksheetTabName: any;
  empVpayDetails: any;
}

const initialState: WorkSheetDataState = {
  // workSheetColumnData: null,
  worksheetTabName: null,
  empVpayDetails: null,
};
export const WorkSheetReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: WorksheetTypes.RouteActionType
): WorkSheetDataState => {
  switch (action.type) {
    // case WorksheetTypes.LOAD_WORKSHEET_COLUMN_DATA: {
    //   return { ...state, workSheetColumnData: action.payload };
    // }
    case WorksheetTypes.LOAD_WORKSHEET_TABNAME: {
      return { ...state, worksheetTabName: action.payload };
    }
    case WorksheetTypes.LOAD_EMP_VPAY_DETAILS: {
      return { ...state, empVpayDetails: action.payload };
    }
    default:
      return state;
  }
};
